import { getInitialTheme } from '@cointracker/ui';
import { themeOverridesPerPath } from '@cointracker/ui/src/theme/utils';
import { themeStore } from '../../store';

interface ThemeProps {
  pathname: string;
}

export function Theme({ pathname }: ThemeProps) {
  themeStore.setState(() => ({
    theme: getInitialTheme({
      themeOverridesPerPath,
      pathname,
    }),
  }));
}
