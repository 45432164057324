import { Theme } from '../utils';

export const themeOverridesPerPath = [
  { pathname: '/', theme: Theme.DARK },
  { pathname: '/coinbaseone', theme: Theme.DARK },
  { pathname: '/coinbase', theme: Theme.DARK },
  { pathname: '/partner/coinbase', theme: Theme.DARK },
  { pathname: '/partner/coinbaseone', theme: Theme.DARK },
  { pathname: '/partner/coinbase-pro', theme: Theme.DARK },
  { pathname: '/partner/turbotax', theme: Theme.DARK },
  { pathname: '/partner/etoro', theme: Theme.DARK },
  { pathname: '/partner/brave', theme: Theme.DARK },
  { pathname: '/partner/blockchain.com', theme: Theme.DARK },
  { pathname: '/partner/phantom', theme: Theme.DARK },
  { pathname: '/partner/uphold', theme: Theme.DARK },
  { pathname: '/partner/trust-wallet', theme: Theme.DARK },
  { pathname: '/partner/uniswap', theme: Theme.DARK },
  { pathname: '/partner/metamask', theme: Theme.DARK },
  { pathname: '/metamask', theme: Theme.DARK },
  { pathname: '/blockchain', theme: Theme.DARK },
  { pathname: '/phantom', theme: Theme.DARK },
  { pathname: '/etoro', theme: Theme.DARK },
  { pathname: '/uphold', theme: Theme.DARK },
  { pathname: '/hrblock', theme: Theme.DARK },
  { pathname: '/opensea', theme: Theme.DARK },
  { pathname: '/coinsquare', theme: Theme.DARK },
  { pathname: '/turbotax', theme: Theme.DARK },
  { pathname: '/landing', theme: Theme.DARK },
  { pathname: '/integrations', theme: Theme.LIGHT },
  { pathname: '/features', theme: Theme.LIGHT },
  { pathname: '/calculator', theme: Theme.LIGHT },
  { pathname: '/portfolio-tracker', theme: Theme.LIGHT },
];
